import React, {useState} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper } from '@mui/material';
import { BannerTypeList} from "app/entities/banner/model/BannerListModel";


interface BannerTableProps {
    banners: BannerTypeList[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

const BannerTable: React.FC<BannerTableProps> = ({ banners, onEdit, onDelete }) => {

    const [hoveredIndex, setHoveredIndex] = useState(null);


    const handleMouseOver = (e, preview, index) => {
        setHoveredIndex(index);
    };

    const handleMouseOut = (e) => {
        setHoveredIndex(null);
    };
    return (
        <TableContainer component={Paper} style={{ borderRadius: '8px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>Created by</TableCell>
                        <TableCell style={{fontWeight: 'bold'}}>Publish Date</TableCell>
                        <TableCell style={{fontWeight: 'bold'}}>Target Link</TableCell>
                        <TableCell style={{fontWeight: 'bold'}}>Preview</TableCell>
                        <TableCell style={{fontWeight: 'bold'}}>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {banners.map((banner, index) => (
                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#F5FCFF' : 'white' }}>
                            <TableCell>
                                <div style={{color:'black', fontWeight: 'bold'}}>
                                    {banner.created_by}
                                </div>
                                <div className="font-size-14" style={{ color: '#636566' }}>
                                    {banner.created_date}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className="font-size-14" style={{ color: '#636566' }}>
                                    {banner.dateFrom } - { banner.dateTo }
                                </div>
                            </TableCell>
                            <TableCell>
                                <a href={banner.urlRedirect} target="_blank" rel="noopener noreferrer">
                                    {banner.urlRedirect}
                                </a>
                            </TableCell>
                            <TableCell>
                                <img src={banner.preview} alt="Preview" style={{ width: '100px', cursor: 'pointer' }} onMouseOver={(e) => handleMouseOver(e, banner.preview,index)} onMouseOut={(e) => handleMouseOut(e)} />
                                {hoveredIndex === index && (
                                    <div style={{ position: 'absolute', top: '65%', left: '60%',transform: 'translate(-60%, -60%)', zIndex: 1 }}>
                                        <img src={banner.preview} alt="Preview" style={{ width:'60%',height: '60%' }} />
                                    </div>
                                )}
                            </TableCell>
                            <TableCell>
                                <button className='btn btn-primary' type="submit" style={{marginRight: '8px'}} onClick={()=> onEdit(banner.id)}>
                                    Edit
                                </button>
                                <button className='btn btn-danger' type="submit" onClick={(event) => onDelete(banner.id)}>
                                    Delete
                                </button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BannerTable;
