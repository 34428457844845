import React, {useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import Cropper from "react-easy-crop";
import {cropImage} from "app/utils/CropUtils";
import CloseDeleteIcon from "app/component/close-delete-icon";

interface EditPreviewProps {
    imageUrl?: string;
    dataImage?: string;
}

const EditPreview: React.FC<EditPreviewProps> = ({ imageUrl ,dataImage}) => {
    const [imagePreview,setImagePreview] = useState('' || null)
    const [edtImagePreview,setEdtImagePreview] = useState('')

    // data from API
    useEffect(() => {

        if (dataImage) {
            setImagePreview(dataImage);
            setEdtImagePreview('')
        } else {
            setImagePreview(false)
        }


    }, [dataImage]);

    // data from update image - handle edit
    useEffect(() => {
        if (imageUrl) {
            setEdtImagePreview(imageUrl)
            setImagePreview('')
        } else {
            setEdtImagePreview('')
        }
    }, [imageUrl]);


    const handleCloseClick = () => {
        setImagePreview('' || null)
        setEdtImagePreview('' || null)

    };

    return (
        <div className="preview-mobile" style={previewStyle}>
            <Typography style={{fontSize: 18, fontWeight: 'bold', marginLeft: '16px', marginBottom: '20px'}}>
                Preview In App Message
            </Typography>
            <div>
                <div style={outerContainerStyle}>
                    <div style={innerContainerStyle}>
                        <div style={mainBoxStyle}></div>
                        <div style={sideBarStyle}></div>
                        <div style={subBoxStyle}></div>
                        <div style={highlightBarStyle}></div>
                        <div style={detailBarStyle}></div>
                        <div style={smallTopBarStyle}></div>
                        <div style={smallMidBarStyle}></div>
                        <div style={highlightDecorationStyle}></div>
                        <div style={overlayStyle}></div>
                    </div>

                    <div style={contentBoxStyle}>
                        {
                            imagePreview || edtImagePreview ?
                                <img src={imagePreview ? imagePreview : edtImagePreview}
                                     alt="Edit Image"
                                     style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                /> :
                                <img
                                    src={'./content/images/bg_placeholder.svg'}
                                    alt="Cropped Image"
                                    style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                />
                        }

                        {imagePreview || edtImagePreview ?
                            <div>
                                <div>
                                    <CloseDeleteIcon style={{
                                        color: 'white',
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        marginLeft: '10px',
                                        cursor: 'pointer'
                                    }} onClick={() => {
                                        handleCloseClick()
                                    }} />
                                    {/*<Button*/}
                                    {/*    color="primary"*/}
                                    {/*    sx={{*/}
                                    {/*        color: 'black',*/}
                                    {/*        fontWeight: 'bold',*/}
                                    {/*        position: 'absolute',*/}
                                    {/*        top: 0,*/}
                                    {/*        right: 0,*/}
                                    {/*        fontSize: 12,*/}
                                    {/*        marginLeft: '10px'*/}
                                    {/*    }}*/}
                                    {/*    onClick={handleCloseClick}*/}
                                    {/*>*/}
                                    {/*    Close*/}
                                    {/*</Button>*/}
                                </div>
                            </div> :
                            <img
                                src={'./content/images/bg_placeholder.svg'}
                                alt="Cropped Image"
                                style={{width: '100%', height: '100%', objectFit: 'cover'}}
                            />
                        }

                    </div>

                </div>
            </div>
        </div>
    )
};

const outerContainerStyle: React.CSSProperties = {
    width: 300,
    height: 729,
    position: 'relative',
    borderRadius: '8px',
};

const innerContainerStyle: React.CSSProperties = {
    width: 364.09,
    height: 725.08,
    position: 'absolute',
    left: -0.05,
    top: 3.87,
};

const mainBoxStyle: React.CSSProperties = {
    width: 358.04,
    height: 725.08,
    position: 'absolute',
    left: 2.48,
    top: 0,
    background: '#383838',
    borderRadius: '48px',
};

const sideBarStyle: React.CSSProperties = {
    width: 1.92,
    height: 80.68,
    position: 'absolute',
    left: 361.5,
    top: 168.77,
    background: '#363636',
    borderRadius: '48px',
};

const subBoxStyle: React.CSSProperties = {
    width: 345.93,
    height: 712.53,
    position: 'absolute',
    left: 9.05,
    top: 5.93,
    background: '#454545',
    borderRadius: '48px',
};

const highlightBarStyle: React.CSSProperties = {
    width: 1.4,
    height: 79.38,
    position: 'absolute',
    left: 362.68,
    top: 169.63,
    background: '#383838',
    borderRadius: '16px',
};

const detailBarStyle: React.CSSProperties = {
    width: 1.92,
    height: 51.99,
    position: 'absolute',
    left: 0.68,
    top: 215.95,
    background: '#363636',
    borderRadius: '16px',
};

const smallTopBarStyle: React.CSSProperties = {
    width: 2.54,
    height: 26.55,
    position: 'absolute',
    left: 0.05,
    top: 98.4,
    background: '#383838',
    borderRadius: '16px',
};

const smallMidBarStyle: React.CSSProperties = {
    width: 1.91,
    height: 51.61,
    position: 'absolute',
    left: 0.7,
    top: 150.74,
    background: '#363636',
    borderRadius: '16px',
};

const highlightDecorationStyle: React.CSSProperties = {
    width: 44.21,
    height: 3.08,
    position: 'absolute',
    left: 159.95,
    top: 26.33,
    background: '#C6C6C6',
    borderRadius: '16px',
};

const overlayStyle: React.CSSProperties = {
    width: 321.93,
    height: 697.46,
    position: 'absolute',
    left: 21.1,
    top: 13.63,
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '48px',
};

const contentBoxStyle: React.CSSProperties = {
    width: 280,
    height: 560,
    position: 'absolute',
    left: 45,
    top: 84,
    background: '#ECEFF4',
    borderRadius: '12px',
};

const previewStyle: React.CSSProperties = {
    background: '#fff',
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '400px',
    margin: '20px 20px',
    padding: '20px',
    fontFamily: "'Spoqa Han Sans', sans-serif",
};

export default EditPreview;
