/*
* The service layer is where the class centralize for API calls.
* Use axios or fetch to make HTTP requests and manage your API URLs.
* */


import axios from "axios";
import {bannerUrlAPI} from "app/entities/banner/config/bannerConfig";
import {Urls} from "app/config/api-collection";
import {BannerType} from "app/entities/banner/model/BannerListModel";
import {BannerItemModel} from "app/entities/banner/model/BannerItemModel";

const token = localStorage.getItem('access_token');

let config = {
    method: 'POST',
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
    }
};

export const getBannerList = async (page : number, size : number):Promise<BannerType> => {
    try {
        const response = await axios.get(`${bannerUrlAPI}`+ Urls.get_banner_list+`/?page=${page}&size=${size}`);
        return {
            code: response.data.code,
            message: response.data.message,
            data: response.data.data,
            error: response.data.error,
            pagination : response.data.pagination
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getBannerById = async (id: number): Promise<BannerItemModel> => {
    try {
        const response = await axios.get(bannerUrlAPI + Urls.get_banner_by_id + id);
        return {
            code: response.data.code,
            message: response.data.message,
            data: response.data.data,
            error: response.data.error
        }
    } catch (error){
        throw error;
    }

}

export const createBanner = async (bannerData: FormData): Promise<any> => {

    try {
        const response = await axios(`${bannerUrlAPI}${Urls.create_banner}`, {
            method: 'POST',
            headers: {
                ...config.headers,
                'Content-Type': 'multipart/form-data',
            },
            data: bannerData
        });


        return response.data;
    } catch (error) {
        console.log('Error creating banner:', error);
    }
};

export const updateBanner = async (id: string, editBannerData: FormData): Promise<any> => {

    try {
        const response = await axios(`${bannerUrlAPI}${Urls.update_banner}`, {
            method: 'POST',
            headers: {
                ...config.headers,
                'Content-Type': 'multipart/form-data',
            },
            data: editBannerData
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteBanner = async (id: number): Promise<any> => {
    try {
        const response = await axios.delete( bannerUrlAPI + Urls.delete_banner + id);
        // if (response.data["message"] === "success") {
        //     await getBannerList()
        // }
        return response.data;
    } catch (error) {
        console.error('Error deleting banner:', error);
        throw error;
    }
};
