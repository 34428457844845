import React, {useEffect, useState} from "react";
import { Pagination, Select } from "@mui/material";
import { FormControl, MenuItem } from "@mui/material";
import {getBannerList} from "app/entities/banner/services/BannerServices";


interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number, size: number) => void;
    onSizeChange: (size: number) => void;
    size: number;
}

const PaginationComponent: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange, onSizeChange, size }) => {

    // change entries data list
    const handleChange = (event) => {

        onSizeChange(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<any>, page: number) => {
        onPageChange(page,size)

    };


    return (
        <div className="row mt-10 font-family-spoqa" style={{justifyContent: 'space-between', width: '100%'}}>
            <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                <div style={{display: "flex"}}>
                    <span className="mt-3 mx-2">Show</span>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 50}}>
                        <Select value={size} onChange={handleChange} label="Limit">
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                    <span className="mt-3 mx-2">Entries</span>
                </div>
            </div>
            <div className="col-lg-5 col-sm-12 col-xs-12 my-2 mt-4" style={{display: 'flex', justifyContent: 'end'}}>
                <Pagination
                    count={totalPages}
                    color="primary"
                    page={currentPage}
                    showFirstButton
                    showLastButton
                    onChange={handleChangePage}/>
            </div>
        </div>
    );
};

export default PaginationComponent;
