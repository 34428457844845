import React, { useState } from "react";
import PreviewBannerMobile from "app/entities/banner/main-menu/create-banner/PreviewBannerMobile";
import BannerForm from "app/entities/banner/main-menu/create-banner/BannerForm";
import {toast} from "react-toastify";

export const CreateBannerContent: React.FC = () => {
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const handleImageUpdate = (file: File | null) => {

        try {
            if (file) {

                const fileType = file.type;
                const isImage = fileType.startsWith('image/');
                const maxFileSize = 2 * 1024 * 1024;

                if (isImage  && file.size <= maxFileSize) {
                    const reader = new FileReader();
                    reader.abort();
                    reader.onload = (e) => setImagePreview(e.target?.result as string);
                    reader.readAsDataURL(file);

                }  else {
                    if (isImage) {
                        toast.error('File size exceeds the 2 MB limit.', { autoClose: 3000 });
                    } else {
                        toast.error('Invalid file type. Only JPEG, JPG and PNG are allowed.', { autoClose: 3000 });
                    }
                }

            } else {
                setImagePreview(null);
            }
        } catch (error){
            toast.error(error)

        }
    };

    return (
        <div className="container" style={{ display: 'flex', gap: '10px', padding: '10px' }}>
            <div style={{ flex: 1 }}>
                <BannerForm onImageUpdate={handleImageUpdate} />
            </div>
            <div style={{ flex: 1 }}>
                <PreviewBannerMobile imageUrl={imagePreview || undefined}/>
            </div>
        </div>
    );
};
