import React from 'react';
import { Button, Typography } from '@mui/material';
import {useHistory} from "react-router-dom";

const Header: React.FC = () => {
    let history = useHistory();
    return (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{padding: '16px'}}>
                <Typography style={{fontSize: '18pt'}}>
                    <b>M-STOCK Pop Up Banner</b>
                </Typography>
                <Typography variant="body1" color={"darkgray"} fontSize={14}>
                    Create and publish Push Notification / In App Mobile
                </Typography>
            </div>
            <div style={{padding: '14', fontSize: '16pt', color: '#F58220'}}>
                <button className='btn btn-primary' type="submit" onClick={() => {
                    history.push(`/stock/banner/create`)
                }}>
                    Create New Pop Up
                </button>
            </div>
        </div>

    );
};

export default Header;
