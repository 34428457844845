enum Environment {
    DEV = 'development',
    PROD = 'production',
    STG = 'staging'
}

// const env = process.env.NODE_ENV || Environment.DEV;
const env = Environment.DEV;

const getBaseUrlAPI = () => {
    const urls = {
        [Environment.DEV]: 'https://api.dev.miraeasset.io',
        [Environment.STG]: 'https://api.stg.miraeasset.co.id',
        [Environment.PROD]: 'https://api.prd.miraeasset.co.id',
    };

    return urls[env] ?? (() => { throw new Error(`Unsupported environment: ${env}`); })();
};

const getUrlBannerMStock = () => {
    return {
        [Environment.DEV]: 'https://dev-career.miraeasset.io/detail-career/',
        [Environment.STG]: 'https://career.miraeasset.io/detail-career/',
        [Environment.PROD]: 'https://career.miraeasset.io/detail-career/',
    }[env] ?? (() => { throw new Error(`Unsupported environment: ${env}`); })();
};

export const bannerUrlAPI = getBaseUrlAPI();
export const urlBannerMStock = getUrlBannerMStock();
