import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const displayDefaultDateTime = () => moment().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const formatDate = (value: string): string => {
    if (!value) return '';

    const date = new Date(value.split('/').reverse().join('-'));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};

export const validateDate = (date: string, type:string) => {
    const selectedDate = new Date(date);
    const today = new Date();
    let result = false;

    if (type === 'from'){
        if (selectedDate < today) {
            result = true;
        }
    } else {
        if (selectedDate > today) {
            result = true;
        }
    }

    return result;
};
