import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory, useParams} from "react-router-dom";
import {DataItem} from "app/entities/banner/model/BannerItemModel";
import {formatDate} from "app/shared/util/date-utils";
import {toast} from "react-toastify";
import {updateBanner} from "app/entities/banner/services/BannerServices";

interface EditBannerFormProps {
    onImageUpdate: (file: File | null) => void;
    dataBanner: DataItem | null
}

const payloadEditFormData = {
    type: "",
    image: null,
    urlRedirect : "",
    dateFrom : "",
    dateTo : "",
    id:"",
}

const EditBannerForm: React.FC<EditBannerFormProps> = ({ onImageUpdate, dataBanner }) => {
    let history = useHistory();
    const [dateFrom, setDateFrom] = useState<string | null>(new Date().toISOString().slice(0, 10));
    const [dateTo, setDateTo] = useState<string | null>(new Date().toISOString().slice(0, 10));
    const [id,setId] = useState('')
    const [urlRedirect, setUrlRedirect] = useState('');
    const [targetPage, setTargetPage] = useState('');
    const [image, setImage] = useState<File | null>(null);

    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [type,setType] = useState('mainbanner')
    const [formData, setFormData] = useState(payloadEditFormData)
    const [isLoading,setIsLoading] = useState(false)
    const [isNewFile,setNewFile] = useState(false)

    const clickBack = () => {
        history.push(`/stock/banner`);
    }

    const [errors, setErrors] = useState({
        startDate: false,
        endDate: false,
        targetUrl: false,
    });

    useEffect(() => {

        setFormData({
            ...formData,
            id: dataBanner.id || "",
            type: dataBanner.type || "mainbanner",
            urlRedirect: dataBanner.urlRedirect || "",
            dateFrom: dataBanner.dateFrom || "",
            dateTo: dataBanner.dateTo || "",
            image: dataBanner.preview, // Reset image if necessary
        });


    }, [dataBanner,image]);



    const handleInputChange = (field: string, value: any) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleEdit = (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true)
        const startDateError = !formData.dateFrom;
        const endDateError = !formData.dateTo;
        const targetUrlError = !formData.urlRedirect;

        setErrors({
            startDate: startDateError,
            endDate: endDateError,
            targetUrl: targetUrlError,
        });


        if (startDateError || endDateError || targetUrlError) {
            toast.warn("Please fill out all fields before submitting the form.", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000,
            });
            setIsLoading(false)
            return;
        }

        submitEdit(formData)


    };

    const formatDateCheck = (dateString: string): string => {

        if (dateString.includes('/')) {
            const [day, month, year] = dateString.split('/');
            return `${year}-${month}-${day}`;
        } else {
            return dateString;
        }
    };

    const submitEdit = async (payload: any) => {

        let dateFrom = formatDateCheck(payload.dateFrom)
        let dateTo = formatDateCheck(payload.dateTo)

        try {
            const edtFormData = new FormData();
            edtFormData.append("id", payload.id);
            edtFormData.append("type", payload.type);
            // edtFormData.append("image", payload.image); // Include the file
            edtFormData.append("url_redirect", payload.urlRedirect);
            edtFormData.append("datefrom", dateFrom || "");
            edtFormData.append("dateto", dateTo || "");

            if (isNewFile){
                edtFormData.append("image", image); // Include the file
            } else {
                edtFormData.append("image", payload.image)
            }


            const response = await updateBanner(payload.id, edtFormData);

            if (response.message === "success"){
                history.push(`/stock/banner`);
                toast.success("Success Update Data Banner",{autoClose: 3000})
            }
            setIsLoading(false)
            return response.data
        } catch (error) {
            if (error.response && error.response.data) {
                const errorData = error.response.data;
                if (errorData.stat_code === 400 && errorData.stat_msg === "image_file_required") {
                    toast.error("Image file is required");
                } else {
                    toast.error("Error while updating banner: " + error.response.data.stat_msg);
                }
            } else {
                toast.error("Error while updating banner: " + error.message);
            }
            setIsLoading(false)
        }
    };

    const editImageUpload = (item: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (item.target.files && item.target.files.length > 0) {
                const file = item.target.files[0];
                const imagePreviewUrl = URL.createObjectURL(file); // Generate a preview URL for the image
                setImage(file)
                onImageUpdate(file);
                setNewFile(true)
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };


    return (
        <form className="container" onSubmit={handleEdit} style={formStyle}>
            <div className="col-16">
                <button className="btn px-0 mb-2" onClick={clickBack}>
                    <div className="font-family-spoqa mb-0 font-size-20" style={{color: 'black'}}>
                        <FontAwesomeIcon icon="arrow-left" color="black"/>
                    </div>
                </button>
            </div>
            <div style={{display: "flex", justifyContent: "flex-start"}}>
                <div style={{marginRight: '10px'}}>
                    <img src="./content/images/file_image_banner.svg" alt="Image"
                         style={{width: '50px', height: '50px'}}/>
                </div>
                <div>
                    <h2 style={headerStyle}>Edit Pop Up Banner</h2>
                    <p style={subTextStyle}>Create and Schedule Notifications for Our Customers</p>
                </div>
            </div>
            <hr style={{border: '0.5px solid lightgray', width: '100%'}}/>

            {/* Publish Date */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Publish Date</label>
                <div style={dateFieldStyle}>
                    <input
                        type="date"
                        value={formatDate(formData.dateFrom)}
                        onChange={(e) => {
                            formData.dateFrom = e.target.value
                            handleInputChange("dateFrom", formData.dateFrom)
                        }}
                        style={inputStyle}
                        required
                    />
                    <span style={{margin: '0 8px'}}> - </span>
                    <input
                        type="date"
                        value={formatDate(formData.dateTo)}
                        onChange={(e) => {
                            formData.dateTo = e.target.value
                            handleInputChange("dateTo", formData.dateTo)
                        }}
                        style={inputStyle}
                        required
                    />
                </div>
            </div>

            {/* Target URL */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Target URL</label>
                <input
                    type="url"
                    placeholder="https://"
                    value={formData.urlRedirect}
                    onChange={(e) => handleInputChange("urlRedirect", e.target.value)}
                    style={inputStyle}
                    required
                />
            </div>

            {/* Target Page */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Target Page</label>

                <input
                    type="url"
                    placeholder="Page"
                    value=''
                    onChange={(e) => setTargetPage(e.target.value)}
                    style={inputStylePage}
                    required
                    disabled={true}
                />
            </div>

            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "20px",
                marginBottom: "8px"
            }}>
                {/* Hidden input for file upload */}
                <input
                    type="file"
                    id="file-upload"
                    style={{display: "none"}}
                    onChange={editImageUpload}
                />

                {/* Custom styled button */}
                <label
                    htmlFor="file-upload"
                    style={btnSelectImage}
                >
                    <img src="./content/images/icon_paperclip.svg" alt="Image"
                         style={{width: '20px', height: '20px'}}/>
                    Select Image
                </label>
            </div>

            {/* Save Button */}
            <button type="submit" style={buttonStyle} onClick={handleEdit}>
                {isLoading ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FontAwesomeIcon icon="spinner" spin style={{ marginRight: 8 }} />
                        Updating...
                    </div>
                ) : (
                    "Update Schedule"
                )}
            </button>
        </form>
    );
};

// Styles
const formStyle: React.CSSProperties = {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '700px',
    maxWidth: '700px',
    margin: '20px auto',
    padding: '20px',
    fontFamily: "'Arial', sans-serif",
};

const headerStyle: React.CSSProperties = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
};

const subTextStyle: React.CSSProperties = {
    fontSize: '14px',
    color: '#666',
};

const fieldGroupStyle: React.CSSProperties = {
    marginBottom: '20px',
    cursor: 'not-allowed',
};

const labelStyle: React.CSSProperties = {
    display: 'block',
    marginBottom: '8px',
    fontSize: '14px',
    color: 'black',
    fontWeight: 'bold'
};

const inputStyle: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
};

const inputStylePage: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    cursor: 'not-allowed',
};

const dateFieldStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'not-allowed',
};

const buttonStyle: React.CSSProperties = {
    backgroundColor: '#f97316', // Orange color
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
};

const btnSelectImage: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 16px",
    border: "1px solid #f57c00",
    borderRadius: "5px",
    backgroundColor: "white",
    color: "#f57c00",
    fontSize: "14px",
    cursor: "pointer",
    fontWeight: "normal",
}

export default EditBannerForm;
