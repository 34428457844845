/*  document-create */
export const apiGetDetailDocument = `/services/documentservice/api/documents/`;
export const apiGetListAttachement = `/services/documentservice/api/dms/get_attachment_from_gcp?fileId=`;
export const apiGetListGroupNo = `/services/uaadocservice/api/group/get-list-document-code`;
export const apiGetDepartmentList = `/services/uaadocservice/api/users/get-active-group`;
export const apiGenerateDocumentNumberByGroup = `/services/documentservice/api/dms/generateDocumentNumber/new?groupId=`;
export const apiDeleteFileUpload = `/services/documentservice/api/dms/delete-attachment`;
export const apiValidasiURL = `/services/documentservice/api/dms/validasi_file_attachment`;
export const apiGetDataSendTo = `/services/documentservice/api/parameter/get-list-send-to?documentSecurity=`;
export const apiGetAllLevelOrganization = `/services/uaadocservice/api/level-organization/get-all-active`;
export const apiGetGroup = `/services/uaadocservice/api/group/get-by-level-organization?levelOrganizationId=`;
export const apiGetApprovers = `/services/uaadocservice/api/group-authority/get-approvers-by-group-id?groupId=`;
export const apiGetAllLevelOrganizationScreener = `/services/uaadocservice/api/level-organization/dropdown-level-organization-by-document-group?documentGroupId=`;
export const apiGetOrganizationScreener = `/services/uaadocservice/api/group/dropdown-group-by-document-group`;
export const apiGetApproversScreener = `/services/uaadocservice/api/personal-identity/group/get-screeners`;
export const apiSaveAsDraft = `/services/documentservice/api/dms/save_as_draft`;
export const apiNextStep = `/services/documentservice/api/documents/get-data-for-preview/new`;
export const apiTemplateDocumentSave = `/services/documentservice/api/template-document/save`;

/* previewnewbackend */
export const apiGetDataPreview = `/services/documentservice/api/documents/get-data-for-preview/new`;
export const apiConverHTMLtoPDF = `/services/documentservice/api/dms/generate-pdf/new`;
export const apiSendFirstStep = `/services/documentservice/api/dms/send-first-step/new`;
export const apiSendSecondStep = `/services/documentservice/api/dms/send-second-step`;

/* change password */
export const apiForgotPassword = `/services/uaadocservice/api/employee/reset-password`;
export const apiChangePassword = `/services/uaadocservice/api/employee/change-reset-password`;

/* anti money laundry api */
export const apiSearchUpload = `/services/amlservices/api/v1/base/upload-watchlist-v2`;
export const apiSearchManual = `/services/amlservices/api/v1/base/customer-data-from-one-v2`;
export const apiExportDownload = `/services/amlservices/api/v1/base/download-by-account-no`;

// export const apiSearchUpload = `/services/amlservices-base/api/v1/base/upload-watchlist`;
// export const apiSearchManual = `/services/amlservices-base/api/v1/base/customer-data-from-one`;
// export const apiExportDownload = `/services/amlservices-base/api/v1/base/download-pengayaan-by-account-no`;

/* Banner */
export const Urls = Object.freeze({
    get_banner_list : `/services/popnotif/api/banner/getAll`,
    get_banner_by_id : `/services/popnotif/api/banner/getBannerById/`,
    create_banner : '/services/popnotif/api/promotion/uploadimage',
    update_banner : '/services/popnotif/api/promotion/updateImage',
    delete_banner : '/services/popnotif/api/banner/deleteBanner/'
})
