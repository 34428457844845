import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './styles.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import IconCalendar from "app/component/icon-calendar";
import Pagination from '@mui/material/Pagination';

import { faCopy } from '@fortawesome/free-solid-svg-icons';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, ModalHeader, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';
import { useParams } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// @ts-ignore
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { baseUrlAPI, urlWebCareer } from './configCareer';

import { Modal, ModalBody } from 'react-bootstrap'
import CloseModal from "app/component/close-modal";

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

export const JobsList = (props: IHeaderProps) => {
    const initialFormData = {
        listStatus: '',
        selectInvestManagement: null,
        start_date: null,
        end_date: null,
    }
    let history = useHistory();
    const [sort, setSort] = useState<any>("post_date_unformat")
    const [ascending, setAscending] = useState<any>(false)
    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [size, setSize] = useState<any>(10)
    const [dataListOfJobs, setDataListOfJobs] = useState<any>([])
    const [formData, setFormData] = useState(initialFormData)
    const [selectedStartDate, setSelectedStartDate] = useState(new Date())
    const [selectedEndDate, setSelectedEndDate] = useState(new Date())
    const [isShareModalOpen, setShareModalOpen] = useState(false);
    const [currentUrl, setCurrentUrl] = useState(null);

    const careerUrl = process.env.API_CAREER_URL
    const handleShareClick = (id) => {
        const fullUrl = `${urlWebCareer()}${id}`;
        setCurrentUrl(fullUrl);
        setShareModalOpen(true);
    };

    const closeModal = () => {
        setShareModalOpen(false);
        setCurrentUrl(null);
    };

    const copyToClipboard = () => {
        if (currentUrl) {
            navigator.clipboard.writeText(currentUrl)
                .then(() => {
                    alert("URL copied to clipboard!");
                })
                .catch(err => {
                    console.error("Failed to copy: ", err);
                });
        }
    }

    useEffect(() => {
        getJobsList()
    }, [page, size, totalPage, keyword, formData, sort, ascending]);

    const token = localStorage.getItem('access_token');

    // get data
    const getJobsList = () => {

        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }

        axios.get(`${baseUrlAPI()}/job/get-list?keyword=${keyword}&status=${formData.listStatus}&page=${page}&limit=${size}&sort_by=${sort} ${!ascending ? 'DESC' : 'ASC'}`, config)

            .then((res) => {
                setDataListOfJobs(res.data.data);
                setTotalPage(res.data.metadata.total_page);
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    interface filterDTO {
        start_date: String,
        end_date: String,
        investManagementId: String,
        search: String,
        page: number
    }
    const [filters, setFilter] = useState<filterDTO>({
        start_date: null,
        end_date: null,
        investManagementId: null,
        search: null,
        page: 1
    })

    /* handle select */
    const handleInput = (name, value) => {
        if (name == 'listStatus') {
            setFormData({
                ...formData,
                [name]: value.value,
            })
            setPage(1)
        } else {
            setFormData({
                ...formData,
                [name]: value.value
            })
            setPage(1)
        }
    }
    const staticOptions = [
        { label: 'All', value: '' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
    ];

    // pagination
    const handleChange = (event) => {
        setPage(1)
        setSize(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleDeleteConfirmation = (id) => {
        Swal.fire({
            title: 'Do you want to Delete?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id)
            }
        })
    }

    const handleDelete = (id) => {

        let config = {
            mode: 'no-cors',
            method: 'POST',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }

        axios.post(`${baseUrlAPI()}/job/delete/${id}`, formData, config)
            .then(response => {
                getJobsList()
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            })
            .catch(error => {
                console.error('Error adding job:', error);
            })
            .finally(() => {

            });
    };

    return (
        <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-28">List Jobs</h1>
                    </div>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-14">Your Dashboard to Employee Recruitment Excellence</h1>
                    </div>
                </div>
            </div>
            <div className="row my-4 justify-content-between font-family-spoqa">
                <div className="col-8 col-md-8 col-lg-8">
                    <div className="d-flex" style={{ flexGrow: 10 }}>
                        <div className="app-search w-100 py-0 bg-white">
                            <div className="position-relative">
                                <input type="text" className="form-control bg-white py-3" value={keyword} placeholder="Cari Jobs" onChange={(e) => {
                                    if (e.target.value === '') {
                                        setKeyword(e.target.value)
                                        getJobsList()
                                        setPage(1)
                                    } else {
                                        setKeyword(e.target.value)
                                        setPage(1)
                                    }
                                }} onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                        setPage(1)
                                        getJobsList()
                                    }
                                }} />
                                <span className="bx bx-search-alt my-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4 col-md-4 col-lg-4">
                    <div className="row">
                        <div className="col-6 col-md-6 col-lg-6">
                            <Select
                                name="listStatus"
                                // defaultValue={{ label: "All", value: '' }}
                                value={staticOptions.find(option => option.value === formData.listStatus)}
                                placeholder="Select Status"
                                options={staticOptions}
                                onChange={(e) => handleInput('listStatus', e)}
                            />
                        </div>
                        <div className="col-6 col-md-6 col-lg-6">
                            <div className="d-flex" style={{ flexGrow: 10 }}>
                                <button className='btn btn-primary' type="submit" onClick={() => {
                                    history.push(`/career/jobs/add`)
                                }}>
                                    Add New Jobs
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card font-family-spoqa">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped table-centered mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>No</th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('position')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Job Qualification</span>
                                            <span className='mt-1'>
                                                {(sort == "position" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "position" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "position") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('division_name')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Division</span>
                                            <span className='mt-1'>
                                                {(sort == "division_name" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "division_name" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "division_name") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('post_date_unformat')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Date Post</span>
                                            <span className='mt-1'>
                                                {(sort == "post_date_unformat" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "post_date_unformat" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "post_date_unformat") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataListOfJobs ? dataListOfJobs.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className='py-2' style={{ wordBreak: 'break-all' }}>{item?.no}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.position}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.division_name}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.post_date}</td>
                                                <td style={{ wordBreak: 'break-all' }}>
                                                    <button
                                                        style={{
                                                            color: item?.status === 'Inactive' ? 'red' : item?.status === 'Active' ? 'blue' : 'inherit',
                                                            backgroundColor: item?.status === 'Inactive' ? 'rgba(255, 0, 0, 0.2)' : item?.status === 'Active' ? 'rgba(0, 0, 255, 0.2)' : 'inherit',
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                            wordBreak: 'break-all',
                                                            width: '50%',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {item?.status}
                                                    </button>
                                                </td>
                                                <td style={{ wordBreak: 'break-all' }}>
                                                    <button className='btn btn-primary my-n2' onClick={() => {
                                                        history.push(`/career/jobs/detail/${item?.id}`)
                                                    }}> Detail
                                                    </button>
                                                    <button className='btn btn-danger my-n2 mx-1' onClick={() => {
                                                        handleDeleteConfirmation(item?.id)
                                                    }}> Delete
                                                    </button>
                                                    <button className='btn btn-info my-n2' onClick={() => {
                                                        handleShareClick(item?.id)
                                                    }}> Share
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )
                                        : <></>
                                }
                            </tbody>

                            {/* Share Modal */}
                            {/*{isShareModalOpen && (*/}
                            <Modal show={isShareModalOpen} centered>
                                <ModalBody onClick={(e) => e.stopPropagation()}>
                                    <div className='d-flex px-4 pt-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <h2 className='mt-1 text-center'>Share</h2>
                                        <div style={{ cursor: 'pointer' }}
                                            onClick={closeModal}>
                                            <CloseModal />
                                        </div>
                                    </div>
                                    <form className="item-id-form p-4 mb-2" onSubmit={(e) => e.preventDefault()}>
                                        <label>URL</label>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <div className="input-group border rounded">
                                                <input
                                                    type="text"
                                                    id="itemId"
                                                    value={currentUrl}
                                                    readOnly
                                                    className="form-control border-0"
                                                    style={{ backgroundColor: 'white' }}
                                                />
                                                <span
                                                    onClick={copyToClipboard}
                                                    className="absolute mt-2 inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                                >
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </span>
                                            </div>
                                            {/* <div className="mt-1 relative rounded-md shadow-sm">
                                                <input
                                                    id="itemId"
                                                    value={currentUrl}
                                                    readOnly type="text"
                                                    className="form-control"
                                                />
                                                <span className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                                    onClick={copyToClipboard}>
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </span>
                                            </div> */}

                                        </div>
                                    </form>

                                </ModalBody>
                            </Modal>
                            {/*)}*/}
                        </table>
                    </div>
                </div>
            </div>
            <div className="row mt-4 font-family-spoqa">
                <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                    <div style={{ display: 'flex' }}>
                        <span className="mt-3 mx-2">Show</span>
                        <span>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                <SelectMui
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={size}
                                    onChange={handleChange}
                                    label="Limit">
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </SelectMui>
                            </FormControl>
                        </span>
                        <span className="mt-3 mx-2">
                            Entries
                        </span>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(JobsList);
