import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import {formatDate, validateDate} from "app/shared/util/date-utils";
import {toast} from "react-toastify";
import {createBanner, getBannerList} from "app/entities/banner/services/BannerServices";

interface BannerFormProps {
    onImageUpdate: (file: File | null) => void;
}

const payloadFormData = {
    type: "",
    image: null,
    url_redirect : "",
    datefrom : "",
    dateto : ""
}

const BannerForm: React.FC<BannerFormProps> = ({ onImageUpdate }) => {
    let history = useHistory();
    const [datefrom, setDateFrom] = useState<string | null>(null);
    const [dateto, setDateTo] = useState<string | null>(null);
    const [url_redirect, setUrlRedirect] = useState('');
    const [targetPage, setTargetPage] = useState('');
    const [image, setImage] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null); // To store image preview URL
    const [type,setType] = useState('mainbanner')
    const [formData, setFormData] = useState(payloadFormData)
    const [isLoading,setLoading] = useState(false)

    const handleImageUpload = (item: React.ChangeEvent<HTMLInputElement>) => {

        try {
            if (item.target.files && item.target.files.length > 0) {
                const file = item.target.files[0];
                const imagePreviewUrl = URL.createObjectURL(file); // Generate a preview URL for the image
                setImageUrl(imagePreviewUrl); // Set the preview URL in state
                setImage(file); // Set the selected image in state
                onImageUpdate(file); // Call the onImageUpdate prop with the selected image
            }

            // item.target.value = ""; // Clear the input value to allow re-upload of the same file
            // setImageUrl('')
        } catch (error) {
            console.error("Error uploading file:", error);
        }

    };

    const [errors, setErrors] = useState({
        startDate: false,
        endDate: false,
        targetUrl: false,
        image: false,
    });

    useEffect(() => {

        if (image) {
            setImage(image);
            setFormData({
                ...formData,
                type,
                image,
                url_redirect,
                datefrom ,
                dateto
            })
        }

    }, [image]);

    const handleSubmit = (e: React.FormEvent) => {

        e.preventDefault();
        setLoading(true)

        const startDateError = !datefrom;
        const endDateError = !dateto;
        const targetUrlError = !url_redirect;
        const imageError = !image;

        setErrors({
            startDate: startDateError,
            endDate: endDateError,
            targetUrl: targetUrlError,
            image: imageError,
        });

        if (startDateError || endDateError || targetUrlError || imageError) {
            toast.warn("Please fill out all fields before submitting the form.", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000,
            });
            setLoading(false)
            return;
        }


        saveSchedule(formData)
            .then((response) => {
                if (response.message === "success") {
                    history.push(`/stock/banner`);
                    toast.success("Success Create Banner",{autoClose: 3000})
                }

                setLoading(false)

            })

    };

    const handleClickBack = () => {
        history.push(`/stock/banner`);
    };

    const saveSchedule = async (payload: any) => {

        try {
            const formData = new FormData();
            formData.append('type', payload.type);
            if (payload.image) {
                formData.append('image', payload.image); // Include the file
            }
            formData.append('url_redirect', payload.url_redirect);
            formData.append('datefrom', payload.datefrom || '');
            formData.append('dateto', payload.dateto || '');

            return await createBanner(formData);
        } catch (error) {
            toast.error('Error while creating banner:', error.message);
        }
    };


    return (
        <form className="container" onSubmit={handleSubmit} style={formStyle}>
            <div className="col-16">
                <button className="btn px-0 mb-2" onClick={handleClickBack}>
                    <div className="font-family-spoqa mb-0 font-size-20" style={{color: 'black'}}>
                        <FontAwesomeIcon icon="arrow-left" color="black"/>
                    </div>
                </button>
            </div>
            <div style={{display: "flex", justifyContent: "flex-start"}}>
                <div style={{marginRight: '10px'}}>
                    <img src="./content/images/file_image_banner.svg" alt="Image" style={{width: '50px', height: '50px'}}/>
                </div>
                <div>
                    <h2 style={headerStyle}>Create Pop Up Banner</h2>
                    <p style={subTextStyle}>Create and Schedule Notifications for Our Customers</p>
                </div>
            </div>
            <hr style={{border: '0.5px solid lightgray', width: '100%'}}/>

            {/* Publish Date */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Publish Date</label>
                <div style={dateFieldStyle}>
                    <input
                        type="date"
                        value={formatDate(datefrom) || ''}
                        onChange={(e) => {
                            if (validateDate(e.target.value, 'from')) {
                                setDateFrom(e.target.value);
                            } else {
                                toast.warn("Date cannot be higher than today's date",{autoClose: 3000});
                                setDateFrom('')

                                // setStartDate(today.toISOString().slice(0, 10));clear
                            }
                        }}
                        style={inputStyle}
                        required
                    />
                    <span style={{margin: '0 8px'}}> - </span>
                    <input
                        type="date"
                        value={formatDate(dateto) || ''}
                        onChange={(e) => {
                            if (validateDate(e.target.value, 'to')) {
                                setDateTo( e.target.value)
                            } else {
                                toast.warn("Date cannot be lower than start date",{autoClose: 3000});
                                setDateTo('')
                            }
                        }}
                        style={inputStyle}
                        required
                    />
                </div>
            </div>

            {/* Target URL */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Target URL</label>
                <input
                    type="url"
                    placeholder="https://"
                    value={url_redirect}
                    onChange={(e) => setUrlRedirect(e.target.value)}
                    style={inputStyle}
                    required
                />
            </div>

            {/* Target Page */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Target Page</label>
                <input
                    type="url"
                    placeholder="Page"
                    value={''}
                    disabled={true}
                    onChange={(e) => {}}
                    style={inputStylePage}
                />
            </div>

            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "20px",
                marginBottom: "8px"
            }}>
                {/* Hidden input for file upload */}
                <input
                    type="file"
                    id="file-upload"
                    style={{display: "none"}}
                    onChange={handleImageUpload}
                />

                {/* Custom styled button */}
                <label
                    htmlFor="file-upload"
                    style={btnSelectImage}
                >
                    <img src="./content/images/icon_paperclip.svg" alt="Image"
                         style={{width: '20px', height: '20px'}}/>
                    Select Image
                </label>
            </div>

            {/* Save Button */}
            <button type="submit" style={buttonStyle} onClick={handleSubmit}>
                {isLoading ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FontAwesomeIcon icon="spinner" spin style={{ marginRight: 8 }} />
                        Creating...
                    </div>
                ) : (
                    "Save Schedule"
                )}
            </button>
        </form>
    );
};

// Styles
const formStyle: React.CSSProperties = {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '700px',
    maxWidth: '700px',
    margin: '20px auto',
    padding: '20px',
    fontFamily: "'Arial', sans-serif",
};

const headerStyle: React.CSSProperties = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
};

const subTextStyle: React.CSSProperties = {
    fontSize: '14px',
    color: '#666',
};

const fieldGroupStyle: React.CSSProperties = {
    marginBottom: '20px',
};

const labelStyle: React.CSSProperties = {
    display: 'block',
    marginBottom: '8px',
    fontSize: '14px',
    color: 'black',
    fontWeight: 'bold'
};

const inputStyle: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    cursor: 'pointer'
};

const inputStylePage: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    cursor: 'not-allowed',
};

const dateFieldStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
};

const buttonStyle: React.CSSProperties = {
    backgroundColor: '#f97316', // Orange color
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
};

const btnSelectImage : React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 16px",
    border: "1px solid #f57c00",
    borderRadius: "5px",
    backgroundColor: "white",
    color: "#f57c00",
    fontSize: "14px",
    cursor: "pointer",
    fontWeight: "normal",
}

export default BannerForm;
